:root {
  --toastify-toast-min-height: 40px;
  --toastify-toast-max-height: 800px;

  --toastify-font-family: inherit;

  --toastify-color-info: #fcefd9;
  --toastify-color-success: #d2f8db;
  --toastify-color-error: #ffdad6;

  --toastify-text-color-info: #f5a623;
  --toastify-text-color-success: #1fa941;
  --toastify-text-color-error: #f52b18;
}

.Toastify__toast-body {
  @apply font-bold;
}

.Toastify__close-button {
  color: inherit;
}
