:root {
  --rdp-cell-size: 31px !important;
  --rdp-accent-color: #457aeb !important;
  --rdp-background-color: #f4f7fb !important;
}

/*****************************************************************************/
/* DatePickerのCSS
/*****************************************************************************/
.date-picker .rdp-caption {
  @apply h-12 border-b-2 border-gray-border pl-5 pr-4 !important;
}

.date-picker .rdp-caption_label {
  @apply mr-16 text-sm !important;
}

.date-picker .rdp-table {
  @apply mx-4 my-3 !important;
}

.date-picker .rdp-nav_icon {
  @apply h-3.5 w-3.5 text-gray !important;
}
.date-picker .rdp-head_cell {
  @apply text-xs font-normal !important;
  color: #7a7f87 !important;
}
